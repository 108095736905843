<template>
  <div class="tabs">
    <router-link
      v-for="(tab, index) in tabs"
      :key="index"
      :to="tab.to"
      class="tabs__link"
      v-show="isVisible(tab.to.params)">
      {{ tab.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: [
    'tabs',
    'item',
  ],
  methods: {
    isVisible(params) {
      if (this.item && params.subsection) {
        if (params.subsection !== 'documentation' && !this.item.acf[params.subsection]) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
