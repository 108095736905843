import i18n from '@/i18n';

const productsSections = [
  {
    label: i18n.t('products.onSale'),
    to: { name: 'ProductsList', params: { section: 'on-sale' } },
  },
  {
    label: i18n.t('products.notForSale'),
    to: { name: 'ProductsList', params: { section: 'not-for-sale' } },
  },
];

export default productsSections;
