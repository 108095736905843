<template>
  <div class="cards">
    <div v-if="isEmptyResponse">{{ $t('global.noResults') }}</div>
    <el-row
      :gutter="20"
      class="cards__list"
      :class="`cards__list--${entity}`"
      type="flex"
      v-loading="isLoading">
      <el-col
        class="cards__item-wrapper"
        :span="8"
        v-for="(item, index) in items"
        :key="index">
        <router-link
          v-if="item"
          class="cards__item"
          :to="{ name: itemRouteName(item), params: itemParams(item) }">
          <div
            class="cards__logo"
            :style="itemLogo(item)"
            :class="itemLogoModifier(item)">
          </div>
          <div class="cards__title">{{ itemTitle(item) }}</div>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  props: [
    'items',
    'isLoading',
    'isEmptyResponse',
    'entity',
    'routeName',
  ],
  methods: {
    itemTitle(item) {
      if (item.label) {
        return item.label;
      }
      if (item.title && item.title.rendered) {
        return item.title.rendered;
      }
      return '';
    },
    itemRouteName(item) {
      if (item && item.routeName) {
        return item.routeName;
      }
      if (this.routeName) {
        return this.routeName;
      }
      return 'PostLogin';
    },
    itemParams(item) {
      if (item && item.isArchive) {
        return { type: item.type, item };
      }
      return { id: item.id, item };
    },
    itemLogo(item) {
      if (item.acf && item.acf.logo) {
        return `background-image: url(${item.acf.logo});`;
      }
      return '';
    },
    itemLogoModifier(item) {
      if (item && item.type) {
        return `cards__logo--market-trend cards__logo--${item.id}`;
      }
      return '';
    },
  },
};
</script>
